import { metricsDataProviderMixinFactory } from '@hypefactors/shared/js/components/charts/MetricsDataProviderMixin'

export default {
  name: 'StoryFactsDataProvider',

  mixins: [metricsDataProviderMixinFactory({ watchFor: 'appliedFilters', vuexModule: 'newsroom/facts' })],

  props: {
    storyId: {
      type: String,
      required: true
    }
  },

  data () {
    return {
      chartData: {}
    }
  },

  methods: {
    fetchData () {
      return this.$api.get(`stories/${this.storyId}/analytics`, {
        params: {
          ...this.appliedFilters
        }
      })
        .then((response) => {
          this.chartData = response.data.data.story
        })
    }
  },

  render (h) {
    return h('div', [this.$scopedSlots.default({
      chartData: this.chartData,
      isLoading: this.isLoading
    })])
  }
}
