<template>
  <div class="StoryFacts">
    <story-facts-data-provider
      :fetch-when="ready"
      :story-id="storyId"
    >
      <div v-loading="isLoading" slot-scope="{ chartData, isLoading }">
        <template v-if="chartData.total_views < 50 && !$store.getters.isStaffMember">
          <div class="m-t-xl is-flex is-aligned-center">
            <div class="message is-warning">
              <div class="message-body">
                {{ $t('pages.newsroom_facts.crunching_data') }}
              </div>
            </div>
          </div>
        </template>
        <template v-else>
          <banded-section
            :title="$t('pages.newsroom_facts.engagements.sidebar_title')"
            :collapsable="false"
          >
            <newsroom-facts-filters @ready="ready = true" />
            <story-facts-summary-totals-section :chart-data="chartData" />
          </banded-section>
          <banded-section
            :title="$t('pages.newsroom_facts.device_distribution.sidebar_title')"
            :collapsable="false"
          >
            <newsroom-facts-device-distribution
              :total="chartData.total_views"
              :chart-data="chartData.pageviews_by_device"
            />
          </banded-section>
          <banded-section
            :title="$t('pages.newsroom_facts.top_sources.sidebar_title')"
            :tooltip="$t('pages.newsroom_facts.top_sources.sidebar_description')"
            :collapsable="false"
          >
            <newsroom-facts-top-sources :chart-data="chartData.pageviews_by_source" />
          </banded-section>
        </template>
      </div>
    </story-facts-data-provider>
  </div>
</template>

<script>
import StoryFactsDataProvider from '@/components/stories/StoryFactsDataProvider'
import NewsroomFactsTopSources from '@/components/stories/NewsroomFactsTopSources'
import NewsroomFactsFilters from '@/components/stories/NewsroomFactsFilters'
import StoryFactsSummaryTotalsSection from '@/components/stories/StoryFactsSummaryTotalsSection'
import NewsroomFactsDeviceDistribution from '@/components/stories/NewsroomFactsDeviceDistribution'

/**
 * @module StoryFacts
 */
export default {
  name: 'StoryFacts',
  components: {
    NewsroomFactsDeviceDistribution,
    StoryFactsSummaryTotalsSection,
    NewsroomFactsFilters,
    NewsroomFactsTopSources,
    StoryFactsDataProvider
  },
  props: {
    storyId: {
      type: String,
      default: ''
    }
  },
  data () {
    return {
      ready: false
    }
  }
}
</script>
