<template>
  <base-facts-summary-totals-section :chart-data="chartData">
    <data-card
      v-if="mostOftenSource"
      :title="$t('components.story_facts_summary_totals.top_source')"
      size="medium"
      class="SquareTopSource is-full-height"
    >
      <span class="has-text-weight-bold">{{ mostOftenSource.link }}</span>
      <template slot="footer">
        <i18n
          tag="div"
          path="components.story_facts_summary_totals.views"
          class="m-t-s"
        >
          <span slot="value">{{ mostOftenSource.views }}</span>
        </i18n>
      </template>
    </data-card>
  </base-facts-summary-totals-section>
</template>

<script>

import BaseFactsSummaryTotalsSection from '@/components/stories/BaseFactsSummaryTotalsSection'

/**
 * @module StoryFactsSummaryTotalsSection
 */
export default {
  name: 'StoryFactsSummaryTotalsSection',
  components: { BaseFactsSummaryTotalsSection },
  props: {
    chartData: {
      type: [Object, Array],
      required: true
    }
  },
  computed: {
    mostOftenSource () {
      const array = Object.entries(this.chartData.pageviews_by_source || {})
      return array.length ? { link: array[0][0], views: array[0][1]['ga:pageviews'] } : undefined
    }
  }
}
</script>
